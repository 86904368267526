import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { Module } from 'vuex'

export const module: Module<any, RootState> = {
  namespaced: true,
  state: {
    popularArtists: [],
    categoryArtists: [],
    popularVenues: [],
    popularCities: []
  },
  mutations: {
    'SET_POPULAR_ARTISTS' (state, popularArtists) {
      state.popularArtists = popularArtists
    },
    'SET_CATEGORY_ARTISTS' (state, categoryArtists) {
      state.categoryArtists = categoryArtists
    },
    'SET_POPULAR_VENUES' (state, popularVenues) {
      state.popularVenues = popularVenues
    },
    'SET_POPULAR_CITIES' (state, popularCities) {
      state.popularCities = popularCities
    }
  },
  getters: {
    getPopularArtists: (state) => state.popularArtists,
    getCategoryArtists: (state) => state.categoryArtists,
    getPopularVenues: (state) => state.popularVenues,
    getPopularCities: (state) => state.popularCities
  },
  actions: {
    async loadPopularArtists ({ commit, dispatch, getters }) {
      const entityType = 'artist'

      if (getters.getPopularArtists.length === 0) {
        const result = await dispatch('loadPopularEntities', { entityType })

        if (result) {
          commit('SET_POPULAR_ARTISTS', result)
        }
      }
    },
    async loadPopularVenues ({ commit, dispatch, getters }) {
      const entityType = 'venue'

      if (getters.getPopularVenues.length === 0) {
        const result = await dispatch('loadPopularEntities', { entityType })

        if (result) {
          commit('SET_POPULAR_VENUES', result)
        }
      }
    },
    async loadPopularCities ({ commit, dispatch, getters }) {
      const entityType = 'city'

      if (getters.getPopularCities.length === 0) {
        const result = await dispatch('loadPopularEntities', { entityType })

        if (result) {
          commit('SET_POPULAR_CITIES', result)
        }
      }
    },
    async loadPopularEntities (_, { entityType }) {
      const limit = 12
      const params = `entityType=${entityType}&limit=${limit}`
      const { result } = await TaskQueue.execute({
        url: processLocalizedURLAddress(
          getApiEndpointUrl(config.popular, 'endpoint') + `?${params}`
        ),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      return result
    },
    async loadPopularArtistsByParent ({ commit }, { parentId, limit = 12 }) {
      const entityType = 'artist'
      const params = `entityType=${entityType}&limit=${limit}&categoryId=${parentId}`
      const { result } = await TaskQueue.execute({
        url: processLocalizedURLAddress(
          getApiEndpointUrl(config.popular, 'endpoint') + `?${params}`
        ),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      if (result) {
        commit('SET_CATEGORY_ARTISTS', result)
      }
    }
  }
}
