import KlaviyoState from '../types/KlaviyoState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import config from 'config'
import fetch from 'isomorphic-fetch'
import rootStore from '@vue-storefront/core/store'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import * as mappers from '../helpers/mappers'
import { processURLAddress, onlineHelper } from '@vue-storefront/core/helpers'
import { Base64 } from '../helpers/webtoolkit.base64.js'
import { KEY } from '../index'
import cloneDeep from 'lodash-es/cloneDeep'
import { Logger } from '@vue-storefront/core/lib/logger';

const encode = (json) => {
  return Base64.encode(JSON.stringify(json)) // ERROR: Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.
}

const currentStoreCode = () => {
  const { storeCode } = currentStoreView()
  const storeMapping = config.klaviyo.storeMapping

  return storeMapping?.[storeCode] ? storeMapping[storeCode] : ''
}

// it's a good practice for all actions to return Promises with effect of their execution
export const actions: ActionTree<KlaviyoState, any> = {
  maybeIdentify ({ state, dispatch }, { user = null, useCache = true, additionalData = {} }): Promise<Response | object> {
    if (state?.customer === null) {
      return dispatch('identify', { user, useCache, additionalData })
    } else {
      return new Promise((resolve) => resolve(state.customer))
    }
  },

  updateCustomerAddress ({ state, dispatch }, { address, useCache = true }): Promise<Response> {
    if (state?.customer === null) {
      return new Promise((resolve, reject) => reject())
    } else {
      return dispatch('identify', { user: state.customer, useCache, additionalData: mappers.mapAddress(address) })
    }
  },

  updateCustomerEmail ({ state, rootState, dispatch }, { email }): Promise<Response> {
    let personalDetails = rootState?.checkout?.personalDetails || {}
    personalDetails.emailAddress = email
    dispatch('checkout/savePersonalDetails', personalDetails, { root: true })
    dispatch('identify', { user: { email }, useCache: true })
  },

  identify ({ commit, dispatch, rootState }, { user, useCache = true, additionalData = {}, removePhone = false }): Promise<Response> {
    if (!user) {
      throw new Error('User details are required')
    }
    const shippingDetails = rootState?.checkout?.shippingDetails
    const userData = user
    const customer = mappers.mapCustomer(userData)

    if (!customer?.email) {
      throw new Error('User email is required')
    }
    if (shippingDetails && shippingDetails?.country) {
      additionalData = {
        ...additionalData, '$country': shippingDetails.country }
    }

    if (removePhone) {
      delete customer['phone_number']
    }

    let body = {
      data: {
        type: 'profile',
        attributes: customer,
        properties: additionalData
      }
    }

    let url = processURLAddress(config.klaviyo.endpoint.client) + '/profiles/?company_id=' + config.klaviyo.public_key
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          revision: '2024-05-15',
          'content-type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(res => {
        if (res && res.status === 400 && customer && customer['phone_number']) {
          dispatch('identify', { user, useCache, additionalData, removePhone: true })
            .then((identify) => resolve(identify))
            .catch(err => {
              Logger.error('Identify Failed', 'klaviyo', { user, err })()
              reject(err)
            })
        } else if (res?.status !== 400) {
          commit(types.SET_CUSTOMER, customer)

          dispatch('registerActivity').catch(_ => {})
          const cacheStorage = StorageManager.get(KEY)
          if (useCache) cacheStorage.setItem('customer', customer)
          resolve(res)

          cacheStorage.getItem('trackQueue').then(items => {
            if (items) {
              cacheStorage.removeItem('trackQueue')
              items.forEach(event => dispatch('track', event).catch(_ => {}))
            }
          })
        } else {
          Logger.error('Identify Failed', 'klaviyo', { user, res })()
          reject(res)
        }
      }).catch(err => {
        Logger.error('Identify Error', 'klaviyo', { user, err })()
        reject(err)
      })
    })
  },

  loadCustomerFromCache ({ commit }): Promise<Record<string, any>> {
    return new Promise((resolve, reject) => {
      const cacheStorage = StorageManager.get(KEY)
      cacheStorage.getItem('customer').then(customer => {
        if (customer) {
          commit(types.SET_CUSTOMER, customer)
          resolve(customer)
        } else {
          resolve(null)
        }
      }).catch(() => reject())
    })
  },

  resetCustomer ({ commit }, useCache = true) {
    commit(types.SET_CUSTOMER, null)
    commit(types.SET_NEWSLETTER_SUBSCRIBED, null)
    commit(types.SET_WATCHING, [])
    if (useCache) {
      const cacheStorage = StorageManager.get(KEY)
      cacheStorage.removeItem('customer')
      cacheStorage.removeItem('backInStockWatching')
    }
  },

  track ({ commit, dispatch, state }, { event, data, time = Math.floor(Date.now()) }): Promise<Response> {
    if (!state?.customer?.email || !onlineHelper.isOnline) {
      return new Promise((resolve, reject) => {
        if (!state?.customer?.email) {
          console.warn('No customer identified')
          reject({ message: 'No customer identified' })
        } else {
          reject({ message: 'No connection' })
        }

        const cacheStorage = StorageManager.get(KEY)
        cacheStorage.getItem('trackQueue').then(items => {
          let newItems = items || []

          newItems.push({ event, data, time })
          cacheStorage.setItem('trackQueue', newItems)
        })
      })
    }
    const storeView = currentStoreView()
    let datetime = new Date(time)

    /** Validate legacy data */
    if (state?.customer?.$email) {
      commit(types.SET_CUSTOMER, {
        email: state.customer.$email,
        first_name: state.customer.$first_name || null,
        last_name: state.customer.$last_name || null
      })
    }
    if (datetime.getFullYear() === 1970) {
      datetime = new Date(time * 1000)
    }
    if (datetime.getFullYear() < 2000) {
      datetime = new Date()
    }
    /** End validation */

    const body = {
      data: {
        type: 'event',
        attributes: {
          properties: data,
          time: datetime.toISOString(),
          value: data.ItemPrice || data.$value || 0,
          value_currency: storeView.i18n.currencyCode,
          metric: {
            data: {
              type: 'metric',
              attributes: {
                name: event
              }
            }
          },
          profile: {
            data: {
              type: 'profile',
              attributes: state?.customer
            }
          }
        }
      }
    }

    const url = processURLAddress(config.klaviyo.endpoint.client) + '/events/?company_id=' + config.klaviyo.public_key
    if (config?.klaviyo?.sendStoreScopeEvents) {
      const storeCode = currentStoreCode();
      if (storeCode) {
        // If store code identified create the event for store scope metric
        const storeScopeBody = Object.assign({}, cloneDeep(body))
        storeScopeBody.data.attributes.metric.data.attributes.name = event + ' ' + storeCode
        fetch(url, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            revision: '2024-05-15',
            'content-type': 'application/json'
          },
          body: JSON.stringify(storeScopeBody)
        }).catch(err => {
          Logger.error('Scoped Track Error', 'klaviyo', { storeCode, event, data, err })()
        })
      } else {
        Logger.error('Scoped Track Error - Missing Store code', 'klaviyo', { event, data })()
      }
    }

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          revision: '2024-05-15',
          'content-type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(res => {
        if (res && res.status === 400 && state?.customer && state?.customer['phone_number']) {
          const customer = state.customer
          delete customer['phone_number']
          commit(types.SET_CUSTOMER, customer)
          dispatch('track', { event, data, time })
            .then((identify) => resolve(identify))
            .catch(err => {
              Logger.error('Track Failed', 'klaviyo', { event, data, err })()
              reject(err)
            })
        } else {
          resolve(res)
        }
      }).catch(err => {
        Logger.error('Track Error', 'klaviyo', { event, data, err })()
        reject(err)
      })
    })
  },

  status ({ commit, state }, email): Promise<boolean> {
    return new Promise((resolve, reject) => {
      fetch(processURLAddress(config.klaviyo.endpoint.subscribe) + '?email=' + encodeURIComponent(email) + '&storeCode=' + config.defaultStoreCode, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      }).then(res => res.json())
        .then(res => {
          if (Array.isArray(res.result) && res.result.length > 0) {
            commit(types.NEWSLETTER_SUBSCRIBE)
            resolve(true)
          } else {
            commit(types.NEWSLETTER_UNSUBSCRIBE)
            resolve(false)
          }
        }).catch(err => {
          reject(err)
        })
    })
  },

  subscribe ({ commit, dispatch, state }, email): Promise<Response> {
    if (!state.isSubscribed) {
      return new Promise((resolve, reject) => {
        fetch(processURLAddress(config.klaviyo.endpoint.subscribe), {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'cors',
          body: JSON.stringify({
            email: email,
            storeCode: config.defaultStoreCode
          })
        }).then(res => {
          commit(types.NEWSLETTER_SUBSCRIBE)
          if (!state?.customer) {
            dispatch('identify', { user: { email } }).then((identify) => resolve(identify))
          } else {
            resolve(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  subscribeAdvanced ({ commit, dispatch, state }, requestData): Promise<Response> {
    return new Promise((resolve, reject) => {
      fetch(processURLAddress(config.klaviyo.endpoint.subscribeAdvanced), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify(requestData)
      }).then(res => {
        if (!state?.customer && requestData.hasOwnProperty('email')) {
          dispatch('identify', { user: { email: requestData.email } }).then((identify) => resolve(identify))
        } else {
          resolve(res)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },

  unsubscribe ({ commit, state, dispatch }, email): Promise<Response> {
    if (state?.isSubscribed) {
      return new Promise((resolve, reject) => {
        dispatch('track', {
          event: 'Request to Unsubscribe',
          data: {
            email: email,
            listId: config.klaviyo.listId
          }
        }).then(res => {
          commit(types.NEWSLETTER_UNSUBSCRIBE)

          if (!rootStore.state.user.current || !rootStore.state.user.current.email) {
            commit(types.SET_CUSTOMER, null)
          }

          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  backInStockSubscribe ({ state, commit, getters }, { product, email, subscribeForNewsletter, useCache = true }): Promise<Response> {
    if (!getters.isWatching(product.sku)) {
      let formData = new URLSearchParams()
      const { storeId } = currentStoreView()

      formData.append('a', config.klaviyo.public_key)
      formData.append('email', email)
      formData.append('g', config.klaviyo.backInStockListId)
      formData.append('variant', product.id)
      formData.append('product', product.id)
      formData.append('platform', config.klaviyo.platform)
      formData.append('store', storeId || config.defaultStoreId)
      formData.append('subscribe_for_newsletter', subscribeForNewsletter)

      return new Promise((resolve, reject) => {
        fetch(processURLAddress(config.klaviyo.endpoint.backInStock), {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          mode: 'cors',
          body: formData.toString()
        }).then(res => {
          res.json().then(json => {
            if (json.success) {
              commit(types.BACK_IN_STOCK_SUBSCRIBE, product.parentSku ? product.parentSku + '-' + product.sku : product.sku)
              if (useCache) {
                const cacheStorage = StorageManager.get(KEY)
                cacheStorage.setItem('backInStockWatching', state.backInStockWatching)
              }
              resolve(json)
            } else {
              reject(json)
            }
          })
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  backInStockUnsubscribe ({ state, commit, getters, dispatch }, { product, email, subscribeForNewsletter, useCache = true }): Promise<Response> {
    if (getters.isWatching(product.sku)) {
      const { storeId } = currentStoreView()

      return new Promise((resolve, reject) => {
        dispatch('track', {
          event: 'Requested Back In Stock Unsubscribe',
          data: {
            email: email,
            listId: config.klaviyo.backInStockListId,
            storeId: storeId || config.defaultStoreId,
            product: product.parentSku ? product.parentSku : product.sku,
            variant: product.sku
          }
        }).then(res => {
          res.json().then(json => {
            if (json.success) {
              commit(types.BACK_IN_STOCK_UNSUBSCRIBE, product.parentSku ? product.parentSku + '-' + product.sku : product.sku)
              if (useCache) {
                const cacheStorage = StorageManager.get(KEY)
                cacheStorage.setItem('backInStockWatching', state.backInStockWatching)
              }
              resolve(json)
            } else {
              reject(json)
            }
          })
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  loadWatchingList ({ commit, dispatch }, useCache = true): Promise<Response> {
    return new Promise((resolve, reject) => {
      const loadFromServer = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          reject({ message: 'Not Implemented' })
        })
      }

      if (useCache) {
        const cacheStorage = StorageManager.get(KEY)
        cacheStorage.getItem('backInStockWatching').then(backInStockWatching => {
          if (backInStockWatching) {
            commit(types.SET_WATCHING, backInStockWatching)
            resolve(backInStockWatching)
          } else {
            loadFromServer().then(res => {
              resolve(res)
            }).catch(err => {
              reject(err)
            })
          }
        }).catch(() => reject())
      } else {
        loadFromServer().then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      }
    })
  },

  productViewed ({ dispatch }, product): Promise<Response> {
    return dispatch('track', { event: 'Viewed Product', data: mappers.mapProduct(product) }).catch(_ => {})
  },

  productAddedToCart ({ dispatch }, product): Promise<Response> {
    return dispatch('track', { event: 'Added to Cart Product', data: mappers.mapLineItem(product) }).catch(_ => {})
  },

  productRemovedFromCart ({ dispatch }, product): Promise<Response> {
    return dispatch('track', { event: 'Removed from Cart Product', data: mappers.mapLineItem(product) }).catch(_ => {})
  },

  async checkoutStarted ({ dispatch }, cart): Promise<Response> {
    let cartMapper

    try {
      const cartMapperOverride = config.klaviyo.mappers.mapCart
      if (cartMapperOverride) {
        cartMapper = await rootStore.dispatch(`${cartMapperOverride}`, cart, { root: true })
      }
    } catch {
      cartMapper = mappers.mapCart(cart)
    }
    return dispatch('track', { event: 'Started Checkout', data: cartMapper || mappers.mapCart(cart) }).catch(_ => {})
  },

  orderPlaced ({ state, dispatch }, order): Promise<Response> {
    return new Promise(async (resolve, reject) => {
      try {
        const addressInfo = order.addressInformation.billingAddress || order.addressInformation.shippingAddress

        if (addressInfo) {
          let user: any = state?.customer

          if (user === null) {
            user = {
              email: addressInfo.email
            }
          }

          user.firstname = addressInfo.firstname
          user.lastname = addressInfo.lastname
          user.telephone = addressInfo.telephone
          user.address = addressInfo

          await dispatch('identify', { user })
        }

        const response = await dispatch('track', { event: 'Placed Order', data: mappers.mapOrder(order) })
        order.products.forEach(product => {
          dispatch('productOrdered', { order, product })
        })

        resolve(response)
      } catch (_) {}
    })
  },

  productOrdered ({ dispatch }, { order, product }): Promise<Response> {
    return dispatch('track', { event: 'Ordered Product', data: mappers.mapOrderedProduct(order, product) }).catch(_ => {})
  },

  searchClicked ({ dispatch }, { klaviyoData }): Promise<Response> {
    return dispatch('track', { event: 'Klevu Search', data: klaviyoData }).catch(_ => {})
  },

  clientActivity ({ dispatch }, { properties }): Promise<Response> {
    return dispatch('track', { event: 'Client Activity', data: properties }).catch(_ => {})
  },

  registerActivity ({ commit, dispatch, state }, { page = null } = {}): Promise<Response> | null {
    try {
      if (state?.customer?.email) {
        const activity = localStorage.getItem('lastActivity');
        const current = Date.now();
        const minInterval = (config?.klaviyo?.activityIntervalMin || 30) * 1000 * 60
        const lastActivity = state?.lastActivity || parseInt(activity, 10) || 0

        if (current - lastActivity > minInterval) {
          commit(types.SET_LAST_ACTIVITY, current)
          localStorage.setItem('lastActivity', current.toString());
          const properties = {
            page: page || (window?.location?.href || 'Unknown'),
            browser: navigator?.userAgent || 'Unknown',
            os: navigator?.platform || 'Unknown'
          }
          return dispatch('clientActivity', { properties }).catch(_ => {})
        }
      }
    } catch (err) {
      Logger.error('Register Activity Error', 'klaviyo', { err })()
    }

    return null
  }
}
