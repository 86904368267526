import config from 'config'
import rootStore from '@vue-storefront/core/store'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { localizedDispatcherRoute } from '@vue-storefront/core/lib/multistore'
import { getThumbnailPath, productThumbnailPath } from '@vue-storefront/core/helpers'
import { router } from '@vue-storefront/core/app'
import { getProductOptions } from '@vue-storefront/core/modules/cart/helpers/productChecksum';
import { validateAndFormatPhoneNumber } from './validateAndFormatPhoneNumber'

export const mapAddress = (address) => {
  return {
    'location': {
      'city': address.city,
      'region': address.state || (address.region && address.region.region) || address.region_code || null,
      'country': address.country || address.country_id,
      'zip': address.zipCode || address.postcode,
      'address1': address.streetAddress || (address.street && address.street[0]) || null,
      'address2': address.apartmentNumber || (address.street && address.street[1]) || null,
      'latitude': address.latitude || null,
      'longitude': address.longitude || null
    },
    'properties': {
      'City': address.city,
      'State / Region': address.state || (address.region && address.region.region) || address.region || address.region_code || null,
      'Country': address.country || address.country_id,
      'Zip Code': address.zipCode || address.postcode,
      'Address': address.streetAddress || (address.street && address.street[0]) || null,
      'Address 2': address.apartmentNumber || (address.street && address.street[1]) || null,
      'Latitude': address.latitude || null,
      'Longitude': address.longitude || null
    }
  }
}

export const mapCustomer = (user) => {
  let customer = {
    'email': user.email || user.emailAddress || user['email'],
    'external_id': user.id || undefined,
    'first_name': user.firstname || user.firstName || user['first_name'] || undefined,
    'last_name': user.lastname || user.lastName || user['last_name'] || undefined,
    'properties': {}
  }

  let address = user.address || (user.addresses?.length && user.addresses.find(addr => addr.default)) || user.addresses?.[0];

  if (address) {
    let phoneNumber = user.telephone || user['phone_number'] || undefined
    if (user.custom_attributes && user.custom_attributes.length) {
      const phone = user.custom_attributes.find(attribute => attribute.attribute_code === 'phone')
      if (phone) {
        phoneNumber = phone.value
      }
    }

    if (!phoneNumber && user.extension_attributes && user.extension_attributes.phone) {
      phoneNumber = user.extension_attributes.phone
    }
    Object.assign(customer, mapAddress(address))
    const validatedPhoneNumber = validateAndFormatPhoneNumber(phoneNumber, address.country_id)
    customer['phone_number'] = validatedPhoneNumber
    customer['properties']['phone_number'] = validatedPhoneNumber
  }

  return customer
}

export const getProductPrice = (product) => {
  return product.price_incl_tax || product.price;
}

export const mapProduct = (origProduct) => {
  let route

  let product = Object.assign({}, origProduct)

  if (config.seo.useUrlDispatcher && product.path) {
    let routeData
    if ((product.options && product.options.length > 0) || (product.configurable_children && product.configurable_children.length > 0)) {
      routeData = {
        path: product.path,
        params: { childSku: product.sku }
      }
    } else {
      routeData = { path: product.path }
    }
    route = localizedDispatcherRoute(routeData)
  } else {
    route = formatProductLink(product, rootStore.state.storeView.storeCode)
  }

  let link = router.resolve(route)
  let categories = []
  const rawCategories = product.category
  const mainCategory = product?.category?.[0]?.category_name || ''

  if (rawCategories) {
    categories = rawCategories.map(cat => cat.name)
  } else if (product.hasOwnProperty('extension_attributes') &&
    product.extension_attributes.hasOwnProperty('category_links') &&
    !!product.extension_attributes.category_links.length &&
    !!rootStore.state.category.list.length) {
    for (let i = 0; i < rootStore.state.category.list.length; i++) {
      const category = rootStore.state.category.list[i]

      for (let j = 0; j < product.extension_attributes.category_links.length; j++) {
        const productCategory = product.extension_attributes.category_links[j]
        if (productCategory.category_id === category.id) {
          categories.push(category.name)
        }
      }
    }
  }

  let imageUrl = productThumbnailPath(product)
  if (imageUrl && !imageUrl.includes('://')) {
    imageUrl = getThumbnailPath(
      imageUrl,
      config.products.thumbnails.width,
      config.products.thumbnails.height
    )

    if (imageUrl && !imageUrl.includes('://')) {
      imageUrl = window.location.origin + imageUrl
    }
  }

  if (product.custom_options?.length) {
    const selectedOptions = getProductOptions(product, 'custom_options')
    let mockedPrice = 0
    let mockedSku = product.sku

    if (selectedOptions.length) {
      selectedOptions.forEach(option => {
        const currentCustomOption = product.custom_options.find(availableOption => availableOption.option_id === Number(option.option_id))

        if (currentCustomOption?.values?.length) {
          const mockedValue = currentCustomOption.values.find(value => value.option_type_id === Number(option.option_value))

          mockedSku += `-${mockedValue?.sku}`
          mockedPrice += mockedValue?.special_price || mockedValue?.price || 0
        }
      })
    }

    product['sku'] = mockedSku
    product['price'] = mockedPrice || product.final_price // fallback to final_price
  }

  if (product.totals?.price_incl_tax) {
    product['price_incl_tax'] = product.totals.price_incl_tax
  }

  const attributesByCode = rootStore?.state?.attribute?.list_by_code
  let location = ''
  if (attributesByCode && product?.location !== null) {
    location = attributesByCode?.location?.options?.find(option => Number(option.value) === product.location ? option : '')
  }

  return {
    'ProductID': product.id.toString(),
    'SKU': product.sku,
    'ProductName': product.name,
    'ItemPrice': getProductPrice(product).toString(),
    'Categories': categories,
    'ProductURL': window.location.origin + link.href,
    'ImageURL': imageUrl,
    'CompareAtPrice': product.special_price,
    'EventType': product?.event_type || '',
    'EventTime': product?.date || '',
    'EventLocation': location?.label || '',
    'EventMainCategory': mainCategory
  }
}

export const mapLineItem = (product) => {
  let productData = mapProduct(product)

  return {
    ...productData,
    'Quantity': product.qty.toString(),
    'RowTotal': productData.ItemPrice * product.qty
  }
}

export const mapCart = (cart) => {
  let userToken = rootStore.getters['user/getUserToken']
  let refreshToken = rootStore.state.user.refreshToken
  let cartId = cart.cartServerToken
  let link = router.resolve({ name: 'checkout', query: { userToken, refreshToken, cartId } })
  let products = []

  for (let i = 0; i < cart.cartItems.length; i++) {
    const product = cart.cartItems[i]
    products.push(mapLineItem(product))
  }

  let value = cart.platformTotals ? cart.platformTotals.grand_total : products.reduce((accumulator, product) => accumulator + product.RowTotal, 0)
  let today = new Date()
  let date = today.getFullYear() + '-' +
     (today.getMonth() + 1) + '-' +
     today.getDate() + '-' +
     today.getHours() + '-' +
     today.getMinutes() + '-' +
     today.getSeconds() + '-'

  return {
    '$event_id': cartId + date,
    'cart_id': cartId,
    '$value': value,
    'ItemNames': products.map(prod => prod.ProductName),
    'CheckoutURL': window.location.origin + link.href,
    'Items': products
  }
}

export const mapOrder = (order) => {
  let products = []

  for (let i = 0; i < order.products.length; i++) {
    const product = order.products[i]
    products.push(mapLineItem(product))
  }

  let subtotal = 0
  let categories = []
  for (let i = 0; i < products.length; i++) {
    const product = products[i]
    subtotal += product.RowTotal
    for (let j = 0; j < product.Categories?.length; j++) {
      const category = product.Categories[j]
      if (categories.indexOf(category) === -1) {
        categories.push(category)
      }
    }
  }

  let result = {
    '$event_id': order.order_id.toString(),
    '$value': subtotal,
    'ItemNames': products.map(prod => prod.ProductName),
    'Categories': categories,
    'Items': products
  }

  if (order.cart) {
    result['$value'] = order.cart.platformTotals.grand_total
    if (order.cart.platformTotals.discount_amount) {
      // result['Discount Code'] = 'Free Shipping'
      result['Discount Value'] = order.cart.platformTotals.discount_amount
    }
  }

  return result
}

export const mapOrderedProduct = (order, product) => {
  return {
    '$event_id': order.order_id.toString() + '_' + product.id.toString(),
    '$value': product.price * product.qty,
    ...mapLineItem(product)
  }
}
